jQuery($ =>
{
    // ========================================
    // Education
    // ========================================

    $('.education_radio').change(e =>
    {
        let $this = $(e.currentTarget)
        let $education_custom_input = $this.closest('.education_container').find('.education_custom_input')

        if($this.val() == 'EFZ')
        {
            $education_custom_input.addClass('d-none')
            $education_custom_input.prop('disabled', true)
        }
        else
        {
            $education_custom_input.removeClass('d-none')
            $education_custom_input.prop('disabled', false)
            $education_custom_input.val('')
        }
    })


    // ========================================
    // Private address
    // ========================================

    $('.address_private_check').change(e =>
    {
        let $this = $(e.currentTarget)
        let $inputs = $this.closest('.address_private_container').find('.address_private_inputs')

        $inputs.toggleClass('d-none', !$this.is(':checked'))
    })


    // ========================================
    // Employee
    // ========================================

    $('.employee').change(e =>
    {
        let $this = $(e.currentTarget)
        let $custom_employee = $this.closest('.employee_container').find('.custom-employee')

        if($this.val() != 0)
        {
            $custom_employee.addClass('d-none')
        }
        else
        {
            $custom_employee.removeClass('d-none')
            $custom_employee.val('')
        }
    })


    // ========================================
    // SMS Reminder
    // ========================================

    $('.sms-checkbox').change(e =>
    {
        let $this = $(e.currentTarget)

        $('.phone-sms-input').toggleClass('d-none', !$this.is(':checked')).focus()
    })
})
